<template>
  <b-modal id="modal-novo-cadastro" hide-footer size="lg">
    <div class="spaceModal">
      <div class="titleModal">{{ $t('modal_new_register.create_account') }}</div>
      <div class="spaceSelectPainel">
        <b-form @submit.stop.prevent="makeAccount2">
          <div class="spacePainels">
            <div class="spaceInputs">
              <b-form-group :label="$t('modal_new_register.label_user_name')" label-for="nameUser" id="step1">
                <b-form-input name="nameUser" id="nameUser" v-model="name" v-validate="{ required: true }"
                  :placeholder="$t('modal_new_register.placeholder_user_name')"></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('name')">
                  {{ $t('modal_new_register.feedback_menssage_user_name') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="spaceInputs">
              <b-form-group :label="$t('modal_new_register.label_user_email')" label-for="emailUser" id="step2">
                <b-form-input name="emailUser" id="emailUser" type="email" v-model="email" v-validate="{ required: true }"
                  :placeholder="$t('modal_new_register.placeholder_user_email')"></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('email')">
                  {{ $t('modal_new_register.feedback_menssage_user_email') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="spaceInputs">
              <b-form-group :label="$t('modal_new_register.label_user_telephone')" label-for="telephoneUser" id="step3">
                <b-form-input name="telephoneUser" id="telephoneUser" v-mask="['(##) ####-####', '(##) #####-####']"
                  v-model="cellphone" v-validate="{ required: true }"
                  :placeholder="$t('modal_new_register.placeholder_user_telephone')"></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('cellphone')">
                  {{ $t('modal_new_register.feedback_menssage_user_telephone') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="spaceInputs">
              <b-form-group :label="$t('modal_new_register.label_user_document')" label-for="documentUser" id="step4">
                <b-form-input name="documentUser" id="documentUser" v-mask="['###.###.###-##', '##.###.###/####-##']"
                  v-model="cpf" v-validate="{ required: true }"
                  :placeholder="$t('modal_new_register.placeholder_user_document')"></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('cpf')">
                  {{ $t('modal_new_register.feedback_menssage_user_document') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>
          <div class="header">
            <b-button @click="makeAccount" class="newPainel" type="submit" variant="primary">
              {{ $t('modal_new_register.button_create_account') }}
            </b-button>
          </div>
        </b-form>
      </div>
    </div>
  </b-modal>
</template>

<script>
import notify from "@/services/libs/notificacao";
import axios from "axios";
import Cookies from "js-cookie";
import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();

export default {
  components: {},
  props: ["type"],
  data() {
    return {
      name: "",
      email: "",
      cellphone: "",
      cpf: "",
    };
  },
  methods: {
    makeAccount() {
      this.$root.$emit("loadOn");
      let data = {
        id: "store",
        name: this.name,
        email: this.email,
        cellphone: this.cellphone,
        cpf_cnpj: this.cpf,
      };
      serviceMember
        .postID(data)
        .then((resp) => {
          var sites = resp.sites;
          Cookies.set("memberAuth", resp.Auth, { expires: 365 });
          // Cookies.set("blogsClub", resp.sites, { expires: 365 });
          this.$store.dispatch("auth/blogSelected", sites[0])
          this.$root.$emit("loadOff")
        })
        .catch(err => {
          var error = JSON.parse(err.response.data);
          var msg = ""
          for (var indice in error) {
            msg += error[indice][0] + "<br>"
          }
          if (msg !== "") {
            notify("erro", msg)
          }

          this.$root.$emit("loadOff")
        });
    },
    saveProject() {
      var data = "";
      if (this.statusDemo) {
        data = {
          site_name: this.nameUser,
          site_description: this.descricaoProject,
          subdomain: this.slugProject,
          create_demo: true
        }
      } else {
        data = {
          site_name: this.nameUser,
          site_description: this.descricaoProject,
          subdomain: this.slugProject
        }
      }
      var that = this;
      this.$root.$emit("loadOn")
      axios
        .post(process.env.VUE_APP_API_HOST + `/site`, data, {
          headers: {
            Authorization: Cookies.get("memberAuth")
          }
        })
        .then(function (resp) {
          var newsite = resp.data
          newsite.domains = []
          newsite.custom_fields_filled = true
          notify("sucesso", "Projeto criado com Sucesso!")
          setTimeout(() => {
            that.$root.$emit(
              "bv::hide::modal",
              "modal-novo-cadastro",
              "#btnShow"
            );
            that.$store.dispatch("auth/blogSelected", newsite)
          }, 3000)
          that.$root.$emit("loadOff")
        })
        .catch(err => {
          var error = err.response.data
          var msg = ""
          for (var indice in error) {
            msg += error[indice][0] + "<br>"
          }
          if (msg !== "") {
            notify("erro", msg)
          }
          that.$root.$emit("loadOff")
        })
    },
  },
};
</script>

<style lang="scss">
#modal-novo-cadastro {
  input.form-control.input-group.has-error {
    border: 1px solid #a94442 !important;
    box-shadow: inset 0 1px 1px #00000014, 0 0 6px #ce8483 !important;
  }

  input.form-control.input-group.has-success {
    border: 1px solid #2b542c !important;
    box-shadow: inset 0 1px 1px #00000014, 0 0 6px #67b168 !important;
  }

  .textSlug {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: var(--fontcolor);
    margin-top: 12px;
    margin-left: 4px;
  }

  .spaceInputs {
    margin-bottom: 30px;

    select {
      border: 1px solid var(--bordercolor);
      box-sizing: border-box;
      border-radius: 30px;
      height: 55px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #333;
      overflow: hidden !important;
      resize: none !important;
    }

    input,
    textarea {
      border: 1px solid var(--bordercolor);
      box-sizing: border-box;
      border-radius: 30px;
      height: 55px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #333;
      overflow: hidden !important;
      resize: none !important;
      background: white;
    }

    input:hover,
    select:hover,
    textarea:hover,
    input:focus,
    select:focus,
    textarea:focus {
      border: 1px solid var(--maincolor);
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #333;
    }

    label {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: var(--fontcolor);
    }
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .newPainel {
    background: var(--maincolor);
    border: 1px solid var(--maincolor);
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    border-radius: 30px;
    height: 55px;
    width: 250px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.2px;
    color: #ffffff;
    padding: 0px 15px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .modal-header {
    border: none !important;
  }

  .spaceModal {
    padding: 5px 30px 0px 30px;
    margin-top: -20px;

    .logout {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #ff0c37;
      margin-top: 10px;
      cursor: pointer;
    }

    .logout2 {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: var(--maincolor);
      margin-top: 10px;
      cursor: pointer;
    }

    .titleModal {
      font-family: Inter;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      letter-spacing: 0.2px;
      color: var(--fontcolor);
    }

    .spaceSelectPainel {
      background: var(--backgroundcolor);
      border-radius: 5px;
      padding: 20px 5px;

      .lineDiv {
        height: 0.5px;
        background: #ededf0;
        width: 100%;
        margin-bottom: 25px;
        margin-top: 25px;
      }

      .flexData {
        display: flex;
        margin-bottom: 20px;
        cursor: pointer;

        .pointer {
          background: rgba(0, 214, 228, 0.1);
          border: 1px solid #00d6e4;
          box-sizing: border-box;
          width: 40px;
          height: 40px;
          border-radius: 100%;
        }

        .title {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: var(--fontcolor);
          margin-left: 20px;
          line-height: 40px;
        }
      }
    }
  }
}
</style>
