<template>
  <div class="heightList">
    <div :class="{
      spaceSelectPainel: !isMobile,
      spaceSelectPainelMobile: isMobile,
    }">
      <div class="spacePainels" v-for="painel in painels" :key="painel.id">
        <div class="flexData">
          <div class="spaceImg" v-if="painel.logo === null">
            <img src="@/assets/icons/logo-fake2.svg" alt="logo" />
          </div>
          <div class="spaceImg" v-else>
            <img :src="painel.logo" alt="logo" />
          </div>
          <div>
            <div class="title">{{ painel.name }}</div>
            <div class="descricao">{{ painel.description }}</div>
          </div>
        </div>
        <button class="btnAccess" @click="selectedPainel(painel)">
          Acessar Painel
          <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 6H15M15 6L9.97436 1M15 6L9.97436 11" stroke="white" stroke-width="1.2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["painels", "isMobile"],
  methods: {
    selectedPainel(data) {
      this.$store.dispatch("auth/blogSelected", data);
    },
  },
};
</script>

<style scoped lang="scss">
.heightList {
  height: 400px;
  width: 100%;
  overflow: auto;
}

.btnAccess {
  background: var(--maincolor);
  border-radius: 30px;
  border: none;
  height: 45px;
  width: 166px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.2px;
  color: #ffffff;
  text-align: end;
  float: right;

  svg {
    margin: 0 10px 0 10px;
  }
}

.heightList::-webkit-scrollbar {
  width: 7px;
}

.heightList::-webkit-scrollbar-thumb {
  background: #ececec;
  border-radius: 30px;
  height: 50px;
}

.toCenter {
  display: flex;
  justify-content: center;
}

.spaceSelectPainel {
  // background: var(--backgroundcolor);
  border-radius: 30px;
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
}

.spacePainels {
  padding: 20px;
  transition: 0.3s;
  margin-bottom: 15px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 30px;
}

.spaceSelectPainelMobile {
  width: 100%;
  // background: var(--backgroundcolor);
  border-radius: 30px;
  padding: 30px;
}

.flexData {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 60px 4fr;
  align-items: center;

  .spaceImg {
    width: 60px;
    height: 60px;
    overflow: hidden;

    img {
      width: 100%;
      border-radius: 3px;
    }
  }

  .title {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #757575;
    margin-left: 20px;
    margin-bottom: 5px;
  }

  .descricao {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    letter-spacing: 0.2px;
    color: #81858e;
    overflow: hidden;
    height: 60px;
    margin-left: 20px;
  }
}
</style>
